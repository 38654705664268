<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/icon.png')"
          class="my-3"
          contain
          height="200"
        />
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="40"
        />
      </v-col>

      <v-col class="mb-4">
        삐삐북 - 독서 기록, 보상, 메모 삐삐와 함께 고고<br>
        나만의 독서 기록과 메모를 만들고 삐삐 통장을 채우자<br>
        <a href="itms-apps://apple.com/app/id1671783078" target="_blank" rel="noopener">App Store 다운로드</a>.
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h1 class="headline font-weight-bold mb-5">
          Application
        </h1>
        <v-row justify="center">
          <v-col
          class="pa-1"
            cols="12"
            md="2"
            v-for="i in [1,2,3,4,5]"
            :key="i"
          >
            <v-img
                contain
                :src="require(`../assets/screenshot/${i}.png`)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
          class="pa-1"
            cols="12"
            md="2"
            v-for="i in [6,7,8,9,10]"
            :key="i"
          >
            <v-img
                contain
                :src="require(`../assets/screenshot/${i}.png`)"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h1 class="headline font-weight-bold mb-5">
          Information Links
        </h1>
        <v-row justify="center">
            <router-link class="px-2" to="/service">서비스 이용약관</router-link>
            <router-link class="px-2" to="/terms">개인정보 취급방침</router-link>
            <router-link class="px-2" to="/license">오픈소스 라이센스</router-link>
        </v-row>

      </v-col>

      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="40"
        />
        <v-img
          :src="require('../assets/icon.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HelloWorld',
  data() {
    return {
      imgs: [
        "@/assets/screenshot/1.png",
        "@/assets/screenshot/2.png",
        "@/assets/screenshot/3.png",
        "@/assets/screenshot/4.png",
        "@/assets/screenshot/5.png",
        "@/assets/screenshot/6.png",
        "@/assets/screenshot/7.png",
        "@/assets/screenshot/8.png",
      ]
    }
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
