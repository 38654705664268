<template>
    <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/3weeks.png')"
          class="my-3"
          contain
          height="200"
        />
        <h1 class="headline font-weight-bold">3weeks</h1>
      </v-col>

      <v-col class="mb-4">
        3weeks - 다이어트, 운동, 공부 습관 플래너<br>
        3주간의 변화. 습관을 만들고 라이프스타일을 바꾸세요.<br>
        <a href="itms-apps://apple.com/app/id1473374528" target="_blank" rel="noopener">App Store 다운로드</a>.
      </v-col>


      <!-- <v-col
        class="mb-5"
        cols="12"
      >
        <h1 class="headline font-weight-bold mb-5">
          Information Links
        </h1>
        <v-row justify="center">
            <router-link class="px-2" to="/service">서비스 이용약관</router-link>
            <router-link class="px-2" to="/terms">개인정보 취급방침</router-link>
            <router-link class="px-2" to="/license">오픈소스 라이센스</router-link>
        </v-row>

      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'ThreeWeeksView'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
