<template>
    <div>
        <h1 style="padding-top: 100px;">bbitbbitbook</h1>
        <h2>개인정보 취급방침</h2>
        <p style="text-align: left; padding: 10%;">
        삐삐북은 개인정보보호법, 정보통신망 이용촉진 등에 관한 법률 등 정보통신 서비스 제공자가 준수하여야 할
        관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 회원 권익 보호에 최선을 다하고 있습니다.
        회사의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.
        <br><br><br>
        1. 수집하고 있는 회원의 개인정보
        1) 수집하는 개인정보의 항목
        필수 항목 : ID(이메일계정), 닉네임, 기재한 사진 및 결제 기록의 정보는
        정보주체가 그 수집에 동의하는 경우 수집됩니다.
        <br><br><br>
        또한 아래의 항목들에 대해서도 안정된 서비스 제공을 위해 합법적인 절차와 회원의 동의를 거쳐 추가로 수집할 수 있습니다.
        ① IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록
        <br><br><br>
        2) 개인정보 수집방법
        회사는 다음과 같은 방법으로 개인정보를 수집하고 있습니다.
        모바일앱, 상담 게시판, 이메일, 이벤트 응모
        협력회사로부터 공동 제휴 및 협력을 통한 정보 수집
        <br><br><br>
        2. 개인정보의 수집 및 이용 목적
        1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산에 활용합니다.
        컨텐츠 제공, 특정 맞춤 서비스 제공, 본인인증, 구매 및 요금 결제, 요금추심
        <br><br><br>
        2) 회원관리를 위해 일부 회원 정보를 활용합니다.
        회원제 서비스 이용 및 인증 서비스에 따른 본인확인, 개인식별, 불량회원(이용약관 제 11조 회원의 의무 각항을 위반하거나
        성실히 수행하지 않은 회원)의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존,
        불만처리 등 민원처리, 고지사항 전달
        <br><br><br>
        3) 신규 서비스 개발 및 마케팅, 광고에 활용합니다.
        신규 서비스 개발 및 인증 서비스, 맞춤서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재,
        이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계, 서비스의 유효성 확인
        <br><br><br>
        3. 개인정보를 수집하는 방법
        모든 회윈이 회사로부터 서비스를 제공받기 위해서는 회원의 개인정보가 필요하며 개인정보는 회원가입을 통해 수집됩니다.
        <br><br><br>
        4. 개인정보의 취급위탁
        회사는 동의 없이 귀하의 개인정보를 외부에 위탁처리 하지 않습니다.
        하지만 서비스 향상 및 안정적인 개인정보 취급을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다.
        개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 고지하겠습니다.
        개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지,
        제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.
        <br><br><br>
        5. 개인정보의 보유 및 이용기간
        회원의 개인정보는 회원가입 후 서비스이용기간이 종료되거나 회원이 계약해지, 탈퇴 등의 사유로 이메일이나 서면을 통해
        개인정보 삭제를 요구하는 경우에는 제3자의 열람과 이용이 불가능한 상태로 처리되며,
        ‘전자상거래 등에서의 소비자보호에 관한 법률’ 제6조(거래기록의 보존 등)에 의하여 아래의 명시 기간 동안 보관관리 합니다.
        * 계약, 청약철회, 회원서비스 제공 등의 거래에 관한 기록 : 5년
        * 대금결제 및 재화 등의 공급에 관한 기록 : 5년
        * 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
        * 서비스 이용 관련 개인정보 : 1년 (통신비밀보호법)
        * 사용자 접속 기록 (인터넷 로그, 접속지 추적 자료) : 6개월
        <br><br><br>
        6. 개인정보 파기절차 및 방법
        회사는 개인정보 보유기간의 경과 혹은 개인정보의 수집 및 이용목적의 달성 등 개인정보가 불필요하게 되었을 때에는
        해당 개인정보를 지체없이 파기하며 절차 및 방법은 다음과 같습니다.
        <br><br><br>
        * 파기절차
        회원이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 잠금장치가 있는 서류보관함)
        내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
        동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
        <br><br><br>
        * 파기방법
        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
        <br><br><br>
        7. 회원 개인정보 정확성을 위한 내용
        회사는 회원이 개인정보를 최신의 상태로 유지하도록 정기적으로 갱신을 유도합니다.
        일부 정보에 대해서는 정기적으로 확인작업이 이루어 집니다. 회원의 부정확한 개인정보로 인하여 사용상의 불편을 줄 수 있으므로
        개인정보 관리자가 판단하기에 확연히 부정확한 개인정보를 기입한 경우에는 정확하지 않은 개인정보를 파기할 수 있습니다.
        <br><br><br>
        8. 회원의 개인정보 안전을 위해 취해질 수 있는 서비스 일시 중단조치
        회사는 회원의 안전한 서비스 이용을 위해서 최선을 다하고 있습니다.
        그러나 원하지 않는 방법에 의하여 회사의 서비스가 훼손을 당하는 경우에는 회원들의 개인정보 보호를 위하여,
        문제가 완전하게 해결될 때까지 회원이 개인정보를 이용한 서비스를 일시 중단 할 수도 있습니다.
        <br><br><br>
        9. 제 3자와의 정보공유 및 제공 관련
        회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 24조의2(개인정보의 제공 동의 등)에 따라 회원의 동의가 있거나
        법률에 특별한 규정이 있는 경우를 제외하고 개인정보를 고지 또는 명시한 범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다.
        또한 개인정보보호법 제59조(금지행위)에 따라 회사의 서비스 제공을 위하여 개인정보를 취급하거나 취급하였던 자는 다음 각호의 행위를 하지 않습니다.
        * 거짓이나 그 밖의 부정한 수단이나 방법으로 개인정보를 취득하거나 처리에 관한 동의를 받는 행위
        * 업무상 알게 된 개인정보를 누설하거나 권한없이 다른 사람이 이용하도록 제공하는 행위
        * 정당한 권한없이 또는 허용된 권한을 초과하여 다른 사람의 개인정보를 훼손, 멸실, 변경, 위조 또는 유출하는 행위
        <br><br><br>
        10. 회원의 개인정보 비밀유지를 위한 내용
        회사는 회원의 개인정보의 비밀을 유지하기 위하여 제3자에게는 회원의 동의 없이 개인정보를 유출하지 않습니다.
        회사는 각종 정부기관의 회원 개인정보의 일방적 제공 요구에 대하여는 회원의 개인정보를 제공하지 않습니다.
        정부기관이 법령에 따른 공식 절차를 완벽하게 거쳐 자료를 요구하는 경우에 한하여 회원의 개인정보를 제공합니다.
        회사는 회원의 개인정보를 회사가 정한 기본서비스 및 기타의 서비스 활동 이외에는 이용하지 않습니다.
        위의 활동에 따라 회원의 정보가 필요할 시에는 별도의 양식을 통한 수집 및 동의의 절차를 거쳐서 회원의 개인정보를 이용합니다.
        <br><br><br>
        11. 인지 못한 회원의 개인정보 및 기타의 불만사항에 관한 처리
        회사가 인지하지 못하고 있는 회원의 개인정보 이용 및 기타의 불만사항에 관하여
        신속하게 회원의 불만사항을 처리하고, 처리한 결과에 대하여 즉시 대응합니다.
        <br><br><br>
        12. 개인정보의 안정성 확보 조치
        회원이 사용하는 ID와 비밀번호는 원칙적으로 이용자만이 사용하도록 되어 있습니다.
        회사는 회원의 개인 부주의로 ID, 비밀번호 등의 개인 정보가 유출되는 경우 또는 발생한 문제가 기본적인 인터넷의 위험성 때문에
        일어나는 경우에 대해 책임을 지지 않습니다. 회원은 암호 보안 의식을 가지고 비밀번호를 자주 변경하고, 로그인시 개인 정보가
        유출되지 않도록 세심한 주의를 기울여야 합니다.
        <br><br><br>
        13. 회원 및 법정대리인의 권리와 그 행사방법
        회원 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.
        회원의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를 위해서는 ‘탈퇴하기’를 통하여 계약 해지 및 탈퇴가 가능합니다.
        회원이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.
        또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
        회사는 회원 혹은 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보를 개인정보처리방침
        ‘5. 개인정보 보유 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        <br><br><br>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        <br><br><br>
        개인정보침해신고센터 / privacy.kisa.or.kr / 국번없이 118
        대검찰청 사이버수사과 / www.spo.go.kr / 국번없이 1301
        경찰청 사이버안전국 / police.go.kr / 국번없이 182
        <br><br><br>
        법령 • 정책 및 보안 기술의 변경에 따라 본 개인정보 처리방침 내용의 추가 • 삭제 및 변경이 있는 경우 변경 시행일의 7일 전부터
        공지사항이나 이메일을 통해 통지합니다.
        <br><br><br>
        시행일자 : 2023년 04월 30일
        <br><br><br>
        </p>
    </div>
</template>