<template>
    <div>
        <h1 style="padding-top: 100px;">bbitbbitbook</h1>
        <h2>서비스 이용약관</h2>
        <p style="text-align: left; padding: 10%;">
        1. 목적
        본 약관은 삐삐북 서비스 및 관련 제반 서비스(이하 '서비스')의 이용과 관련하여 당사와 서비스 이용자(이하 '회원')간에 권리ㆍ의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.
        <br><br><br>
        2. 용어의 정의
        본 약관에서 사용하는 용어의 정의는 다음과 같고 정의된 용어의 정의 외에는 관계법령 및 기타 일반적인 상례와 관례에 의합니다.
        
        * 회원: 서비스에 접속하여 서비스를 이용하는 모든 고객을 말합니다.
        * 아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 정하고 당사가 승인하는 문자와 숫자의 조합을 말합니다.
        * 회원정보: 당사가 서비스 이용을 제공하기 위해 수집한 회원의 닉네임, 이메일, 프로필 사진 등 회원의 개인정보를 말합니다.
        * 게시물: 당사가 제공하는 서비스에 회원이 게시한 부호, 문자, 이미지(사진포함) 및 각종 파일과 URL 링크 등을 의미합니다.
        * 캐롯: 서비스에서 투표를 진행하는데 필요한 서비스 내 포인트를 말합니다.
        <br><br><br>
        3. 약관의 효력과 개정
        * 본 약관은 서비스에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.
        * 당사는 약관의 규제에 관한 법률 등의 관계법령에 위배되지 않는 범위 내에서 이 약관을 개정할 수 있습니다.
        * 당사는 약관을 개정할 경우 개정이유와 적용일자를 명시하여 개정약관의 적용일자 7일 전부터 공지사항이나 이메일을 통해 공지합니다.
        * 당사가 개정 약관을 공지 또는 통지하면서 회원이 7일 내에 거부의사를 표시하지 않으면 승인한 것으로 본다는 뜻을 공지 또는 통지하였음에도 불구하고 회원이 명시적으로 거부의사를 밝히지 않은 경우 회원이 개정약관에 동의한 것으로 간주합니다.
        * 회원은 개정 약관에 동의하지 않는 경우 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.
        * 이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.
        <br><br><br>
        4. 약관 외 준칙
        본 약관에 명시되지 않은 사항에 대해서는 관계법령 및 서비스를 이용하면서 고지되는 세부이용지침 등의 규정에 따릅니다.
        <br><br><br>
        5. 이용계약 체결
        1) 이용계약은 회원이 되고자 하는 고객(이하 '회원가입 신청자')이 약관의 내용에 동의한 후 당사가 정한 신청양식에 따라 서비스 이용을 신청하고 당사가 이를 승낙함으로써 성립합니다.
        2) 당사는 다음 각호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
        * 타인의 명의를 이용하거나 도용하여 신청한 경우
        * 허위 정보 기재 또는 당사가 제시한 내용을 기재하지 않은 경우
        * 회원가입 신청자가 약관 또는 당사가 정한 서비스 운영정책에 회원자격을 상실한 경우
        * 기타 회원가입 신청자의 귀책사유로 인하여 승인이 불가능하다고 판단되는 경우
        * 서비스 이용 후 회원이 당사에 등록한 결제수단의 임의 해지 및 지급 정지, 지불 불능 등의 사유로 정당한 사유 없이 당사가 청구한 서비스 요금을 납부하지 아니한 경우
        3) 당사는 서비스관련 설비가 부족하거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
        4) 당사는 서비스 이용에 필요한 회원 정보 및 기타 정보 항목을 추가하거나 변경할 수 있습니다.
        5) 회원은 서비스내의 회원탈퇴 메뉴를 통해 직접 회원탈퇴를 할 수 있습니다.
        <br><br><br>
        6. 회원정보의 변경
        * 회원은 서비스내 개인정보 관리 메뉴를 통해서 본인의 개인정보를 열람할 수 있으며 아이디, 닉네임, 프로필 사진을 제외한 개인정보는 수정할 수 있습니다.
        * 회원은 개인정보가 변경되었을 경우 지체없이 수정해야 하며, 수정하지 않음으로 인해 발생하는 모든 불이익에 대해서는 당사가 책임지지 않습니다.
        <br><br><br>
        7. 서비스의 이용
        1) 당사는 회원에게 아래와 같은 서비스를 제공합니다.
        * 삐삐북 : 독자의 독서 기록과 메모장 기록 서비스
        2) 회원은 이용계약 체결이 완료되면 바로 서비스 이용이 가능합니다.
        3) 당사는 서비스의 제공에 필요한 경우 서비스 점검을 실시할 수 있으며, 점검시간을 미리 공지하거나 회원에게 통보합니다.
        4) 당사는 업무상, 운영정책상, 또는 기술상의 장애로 인하여 서비스를 일시 중지할 수 있으며, 이 경우에는 서비스에 공지하거나 회원에게 사전 또는 사후 통지를 해야 합니다.
        5) 당사는 서비스를 메뉴별로 이용 가능 시간을 별도로 정할수 있으며 이경우 그 내용을 공지합니다.
        6) 당사는 당사가 정한 서비스 정책에 따라 회원별 이용시간, 이용횟수, 서비스 메뉴 등을 세분화하여 이용에 차등을 둘 수 있습니다.
        7) 당사가 운영상 또는 기술상의 이유로 회원이 구매한 '유료서비스'를 제공할 수 없게 되는 경우에는 회원에게 통지하고 서비스내 당사에서 제시한 조건에 따라 회원에게 보상합니다.
        8) 당사는 서비스 장애 발견시 적극 서비스 개선에 노력합니다.
        <br><br><br>
        8. 서비스 이용중지 또는 계약해지
        1) 당사는 다음 각호에 해당하는 회원에 대하여는 별도의 공지나 통보없이 회원의 서비스 이용을 중지하거나 계약을 해지할 수 있습니다.
        * 회원 신청 또는 개인정보 변경시 타인의 정보를 도용하거나 허위 내용을 등록한 경우
        * 당사가 서비스내 공지한 게시물 운영정책을 준수하지 않은 경우
        * 당사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위를 할 경우
        * 타인 동의 없이 타인의 개인정보를 수집하는 행위를 할 경우
        * 당사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위를 할 경우
        * 당사와 기타 제3자의 저작권 등 지적 재산권을 침해하는 경우
        * 기타 불법적이거나 부당한 행위를 하거나 관련 법령에 위배되는 행위를 할 경우
        * 전체 사용자 이익에 반하거나 서비스 목적에 위배되는 행위를 할 경우
        2) 이용 중지가 될 수 있는 구체적인 항목은 아래와 같습니다.
        * 선정적이거나 노출이 심한 사진 개제
        * 방향성에 맞지 않은 장난성 사진 개제
        * 타인의 사진을 도용한 사진 개제
        * 성적인 표현이 담긴 글 개제
        * 욕설, 비하성 발언등이 담긴 글 개제
        * 허위성이 있는 프로필 개제
        * 목적성과 맞지 않은 상업적인 프로필 개제
        3) 회원은 본 약관의 규정, 이용안내, 운영정책 등 서비스와 관련하여 공지한 내용을 준수해야 하며 당사 업무 및 서비스 운영에 방해되는 행위를 해서는 안됩니다.
        4) 회원은 전 1, 2 항을 준수하지 않아 당사나 타인에게 입힌 손해를 배상할 책임이 있습니다.
        5) 당사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.
        * 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
        * 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우
        6) 당사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
        7) 당사는 전항규정에 의하여 서비스의 이용을 제한하거나 정지한 때에는 그 사유 및 제한기간 등을 지체 없이 이용회원에게 알려야 합니다.
        <br><br><br>
        9. 유료서비스의 구매
        * 회원이 유료 서비스를 이용하기 위해 캐롯을 구입하고자 하는 경우, 당사가 별도로 정한 방법에 따라 스스로 구매 신청을 합니다.
        * 당사가 회원에게 캐롯을 부여함으로써 구매 신청 승낙 및 구매 계약이 성립하는 것으로 합니다.
        * 캐롯 구입 대금의 금액, 지불 방법 및 유료 서비스를 이용하기 위해 필요한 캐롯 수 등은 별도로 정합니다.
        * 회원의 캐롯 구매내역은 당사의 시스템 DB에 저장되어 구매 및 구매 이후에 생기는 문제를 관리 할 수 있도록 합니다.
        <br><br><br>
        10. 환불 및 결제 취소
        1) 회원은 당사가 제공하는 다양한 결제수단을 통해 유료서비스를 이용할 수 있으며, 결제가 비정상적으로 처리되어 정상처리를 요청할 경우 당사는 회원의 결제금액을 정상처리 할 의무를 집니다.
        2) 당사는 부정한 방법 또는 당사가 금지한 방법을 통해 충전 및 결제된 금액에 대해서는 이를 취소하거나 환불을 제한할 수 있습니다.
        3) 회원은 다음 각 호의 사유가 있으면 아래의 규정에 따라서 당사로부터 결제 취소, 환불 및 보상을 받을 수 있습니다.
        * 결제를 통해 사용할 수 있는 서비스가 전무하며 그에 대한 책임이 전적으로 당사에 있을 경우 (단, 시스템 정기 점검 등의 불가피한 사유로 인한 경우는 제외)
        * 당사 또는 결제대행사의 시스템 오류로 인하여 결제가 중복으로 이루어진 경우
        * 서비스 중단 등 당사의 잘못으로 인해 당사가 회원에게 해지를 통보하는 경우
        * 기타 소비자 보호를 위하여 당사에서 별도로 정하는 경우
        4) 환불 및 결제 취소 절차는 다음 각 항목과 같습니다.
        * 환불을 원하는 회원은 고객센터를 통해 이용자 본인임을 인증하는 절차를 거쳐 접수하셔야 하며 본인 인증과 동시에 환불을 신청하여야 합니다.
        * 당사는 회원이 환불 요청 사유가 적합한지를 판단하고 환불사유가 존재하고, 적합한 절차를 거친 것으로 판명된 경우 회원에게 결제금액을 환불합니다.
        * 당사는 회원에게 환불되어야 할 금액 산정 방식과 절차를 회원에게 상세히 설명한 후 회원에게 해당 환불 및 결제 취소 처리합니다.
        * 회원이 구매한 유상 아이템(캐롯 등)을 일체 사용하지 않은 경우, 결제 후 7일 내에는 전액 환불이 가능하며 일부 사용한 경우에는 환불이 불가능합니다.
        * 위항에 해당하는 경우 등 당사의 정책상 환불 사유에 해당할 경우 당사는 회원에게 환불을 위한 일정한 절차 및 환불 입금 계좌번호 등의 금융정보를 이용자에게 요청할 수 있습니다. 회원이 이에 정보를 제공하는 경우에 한해 환불이 가능하며, 회원이 환불 요청시 이러한 개인정보 제공에 동의하는 것으로 간주합니다.
        5) 회원의 요청에 의한 환불 처리 시 환불 금액 정산은 결제시점, 잔여 아이템의 수, 아이템 종류에 따라 마련된 환불정책에 의해 처리됩니다. (4항 참조)
        6) 회원이 이용약관과 서비스 및 당사 정책을 위반함으로 인해 이용정지 또는 강제이용중단 조치 되는 경우 환불 및 보상하지 않습니다.
        7) 회원의 자진 탈퇴 또는 매칭 중단 신청으로 인해 계약이 해지되는 경우, 회원이 보유한 유료 부가서비스 이용권리는 자동으로 소멸되어 복구 및 환불이 불가능합니다.
        8) 신원 인증과정에서 회원의 등록정보가 허위 정보로 판명되거나 이용 조건에 부합되지 않는 것이 판명될 경우 강제 이용 중지 또는 영구 이용 중지 조치가 되며 회원 본인의 귀책사유로 인한 것이므로 환불 및 보상이 불가능합니다.
        9) 당사와 회원간에 발생한 분쟁은 전자거래기본법 제32조에 의거하여 설치된 기관인 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
        10) 제휴 및 협력사를 통해 구매한 상품권이나 혹은 이벤트 상품 및 기타 상품은 기본적으로 환불 및 보상이 불가능하여 별도 공지된 환불규정이 있을 경우에는 그 규정을 따릅니다.
        <br><br><br>
        11. 개인정보보호 의무
        당사는 '정보통신망법' 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 당사의 “개인정보 처리방침”이 적용됩니다. 다만, 당사의 공식 서비스 이외의 링크된 서비스에서는 당사의 “개인정보 처리방침”이 적용되지 않습니다.
        <br><br><br>
        12. 게시물의 관리
        1) 당사는 회원의 게시물이 당사에서 공지한 게시물 운영정책 및 '정보통신망법', '저작권법'등 관련법에 위반되는 내용을 포함하는 경우, 회원의 동의 없이 해당 게시물의 비노출 및 삭제 처리를 할 수 있습니다.
        2) 당사는 다음 각 호에 해당하는 회원의 게시물은 회원에게 사전 통지없이 게시물 비노출, 삭제처리 및 회원의 자격 제한, 정지 또는 회원탈퇴시킬 수 있습니다.
        * 다른 회원의 신고가 3회 이상 접수된 경우
        * 욕설 및 비속어를 반복적으로 사용한 게시물
        * 성적발언으로 모욕감을 주거나 조건 만남, 성매매(윤락행위, 원조교제 등)관련 게시물
        * 의미없는 자음 모음 나열 및 장난식 기호를 연발한 게시물
        * 신체 노출이 심한 사진 및 (준)성인 화보를 등록한 게시물
        * 정치적, 이념적 편향성이 강하거나 특정 집단 및 인물을 비난 또는 홍보하는 게시물
        * 타인을 비방, 모욕하거나 사생활 침해 및 개인정보(아이디 등)를 유출하는 게시물
        * 당사의 운영진, 직원 또는 관계자를 사칭하는 게시물
        * 광고, 사기, 낚시, 도배의 성격을 지닌 게시물
        * 폭력, 비행, 사행심을 조장하는 게시물
        * 서비스의 정상적인 운영을 방해하는 게시물
        * 미풍양속을 해하거니 법령에 위반하는 게시물
        * 기타 건전한 상식에 의거하여 게시가 부적합하다고 판단되는 게시물
        <br><br><br>
        13. 게시물의 저작권
        1) 회원이 서비스내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.
        2) 회원은 자신이 서비스내에 게시한 게시물을 당사가 다음 각 호의 목적으로 사용하는 것을 허락합니다.
        * 당사에서 운영하는 다른 사이트에 게시물을 복제ㆍ전송ㆍ전시하는 것.
        * 당사의 서비스를 홍보하기 위한 목적으로 미디어, 통신사 등에게 게시물의 내용을 보도, 방영하게 하는 것. 단, 이 경우 당사는 회원의 개별 동의없이 미디어, 통신사 등에게 게시물 또는 회원정보를 제공하지 않습니다.
        3) 기타 전항의 목적 외에 당사가 회원의 게시물을 이용하고자 하는 경우에는 사전에 전화, 메일 등의 방법으로 회원의 동의를 얻어야 합니다.
        4) 회원이 서비스에 게시물을 게재하는 것은 다른 회원이 게시물을 서비스 및 서비스에 링크된 사이트 내에서 사용하거나 당사가 검색결과로 사용하는 것을 허락한 것으로 간주합니다.
        5) 회원이 이용계약을 해지 시에는 회원이 서비스에 게시한 게시물은 삭제됩니다. 다만, 다른 회원에 의해 신고를 받은 게시물은 서비스에서는 삭제되지만 필요에 따라 당사가 1년 동안 보관할 수 있습니다.
        6) 당사는 서비스 운영정책상 또는 당사가 운영하는 사이트간의 통합 등을 하는 경우 게시물의 내용을 변경하지 아니하고 게시물의 게재위치를 변경ㆍ이전하거나 사이트간 공유로 하여 서비스할 수 있으며, 게시물의 이전ㆍ변경 또는 공유를 하는 경우에는 사전에 공지합니다.
        <br><br><br>
        14. 당사의 의무
        1) 당사는 본 약관 및 관련법을 준수하고, 안정적으로 서비스를 제공하기 위해 최선을 다합니다.
        2) 당사는 회원의 개인정보를 본인의 동의없이 제3자에게 제공하지 않습니다.
        3) 당사는 “개인정보처리방침”을 공지하고 준수하며 회원정보를 취급함에 있어 안정성 확보 및 기술적 대책을 수립 운영합니다.
        4) 당사는 서비스관련 회원의 불만사항이 적당하다고 인정할 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 일정을 서비스 내 공지하거나 회원에게 통지합니다.
        5) 당사는 유료서비스 제공 시 유료서비스의 내용, 이용방법, 이용료, 결제방법 등을 명확히 공지해야 합니다.
        6) 당사의 고의나 중과실에 의해 회원에게 손해가 발생한 경우 당사에서 책임을 부담하며, 그 책임의 범위는 통상 손해에 한합니다.
        <br><br><br>
        15. 회원의 의무
        1) 회원은 본 약관, 운영정책, 관계법령 및 서비스 이용과 관련하여 당사가 회원에게 통지하는 사항을 준수해야 하며, 기타 당사의 업무에 방해되는 행위를 해서는 안됩니다.
        2) 회원은 회원신청양식 기재시나 회원정보 변경 시 모든 정보를 사실에 의거하여 작성하여야 하며, 허위 또는 타인의 명의(또는 정보)를 이용한 경우 서비스 이용이 중지되며 회원의 권리를 주장할 수 없습니다.
        3) 회원은 서비스를 이용하여 광고, 해킹, 음란물 유포 및 관련 상업행위, 불법 소프트웨어 배포 등을 할 수 없으며 이를 위반 시 관계기관에 의한 구속 등 법적 조치를 당하며 회원은 이와 같은 행위와 관련하여 당사에 손해배상 의무를 집니다.
        4) 회원은 서비스 이용권한을 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
        5) 회원은 회원 아이디 및 비밀번호를 철저히 관리하여야 하며, 관리소홀 및 부정사용 등에 의해 발생하는 모든 결과의 책임은 회원에게 있습니다.
        <br><br><br>
        16. 정보의 제공
        1) 당사는 서비스 이용에 필요한 권리 및 의무 등에 관한 사항을 회원의 공지사항이나 이메일 등을 통해 통지할 수 있습니다.
        2) 당사는 서비스 운영에 필요하다고 인정되는 다양한 정보를 공지사항이나 이메일 등의 방법으로 회원에게 제공할 수 있습니다. 다만 이메일을 통한 정보 제공은 약관의 개정 및 관련법 공지, 거래관련 정보, 고객센터 답변 등을 제외하고 회원이 수신거절을 할 수 있습니다.
        <br><br><br>
        17. 광고의 게재
        1) 당사는 서비스내 광고를 게재할 수 있습니다.
        2) 광고가 게재된 이메일을 수신한 회원은 수신거절을 할 수 있으며 수신 거절한 회원에게는 광고를 발송하지 않습니다.
        3) 회원이 서비스상에 게재되어 있는 광고를 이용하여 발생하는 모든 문제는 회원과 광고주간에 직접 해결해야 하며, 이와 관련하여 당사는 어떠한 책임도 지지 않습니다.
        <br><br><br>
        18. 권리의 귀속
        서비스에 대한 저작권 및 지적재산권은 당사에 귀속됩니다. 단, 회원의 게시물 및 제휴 계약에 따라 제공된 저작물 등은 제외합니다.
        <br><br><br>
        19. 손해배상
        1) 회원이 본 약관을 위반하여 당사에 손해가 발생하게 되는 경우, 본 약관을 위반한 회원은 당사에 발생한 모든 손해를 배상해야 합니다.
        2) 회원이 서비스를 이용하면서 행한 불법행위로 인하여 당사가 회원 이외의 제3자로부터 손해배상 청구 또는 소송 등 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 당사를 면책시켜야 하며 그로 인해 당사에 발생한 손해를 배상하여야 합니다.
        <br><br><br>
        20. 면책사항
        1) 당사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면책됩니다.
        2) 당사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.
        3) 당사는 회원이 서비스에 게재한 정보의 신뢰도, 정확도 등의 내용에 관해서는 책임을 지지 않습니다.
        4) 당사는 회원간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 발생한 분쟁에 대해서는 개입할 의무가 없으며 책임을 지지 않습니다.
        5) 회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우 책임을 지지 않습니다.
        6) 기타 당사의 귀책사유가 없는 통신서비스 등의 장애로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면책됩니다.
        <br><br><br>
        21. 관할 법원
        본 약관은 한국법을 준거법으로 하며, 본 서비스에 기인 또는 관련하여 고객과 당사 간에 발생한 분쟁에 대해서는 당사의 본사소재지 관할법원을 제1심 재판소로 합니다.
        <br><br><br>
        22. 회사의 연락처
        * 상호 : 코드인코드
        * 이메일 주소 : bbitbbit.book@gmail.com
        <br><br><br>
        2023년 4월 30일 개정
        </p>
    </div>
</template>